/* eslint-disable max-statements */
import { useState, useCallback, useRef, useEffect } from 'react';
import { isPlatformWeb } from 'renative';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';
// DONT CHANGE IMPORT PATHS BELOW OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useAnalyticsData } from '@24i/nxg-sdk-smartott-shared/src/context/AnalyticsData';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useModal } from '@24i/nxg-sdk-gluons/src/context/Modal';
import { useUpdateEffect } from '@24i/nxg-core-utils';
import { ASYNC_STORAGE_KEY_USER_TOKEN } from '@24i/nxg-core-utils/src/constants';
import { log, logError } from '@24i/nxg-core-utils/src/logger';
import { Storage } from '@24i/nxg-sdk-quantum';
import {
    IPlayerError,
    PlayerBase,
    PlayerError,
    SeekType,
    ISourceTimelineEventType,
} from '@24i/player-base';
import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import {
    Asset,
    ASSET_TYPE,
    BlockedItem,
    BLOCKING_REASON_TYPES,
    Broadcast,
    Episode,
    QUERY_KEYS,
    Series,
    isEpisode,
} from '@24i/nxg-sdk-photon';
// DONT CHANGE IMPORT PATHS ABOVE OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { useSeriesDataManager, useSourceManager } from '../managers';
import { usePlayerEngine } from '../../../context/PlayerEngine';
import { useDefaultOptions } from '../hooks/useDefaultOptions';
import { useErrorHandling } from '../hooks/useErrorHandling';
import useContinueWatchingQuery, {
    getContinueWatchingOffset,
} from '../../../hooks/query/continueWatching/useContinueWatchingQuery';
import {
    BackStageAnalyticsProperties,
    SharedViewModelProps,
    SharedViewModelReturnType,
} from '../types';
import { calculateEventOffset } from '../helpers';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';
import useBlockedModal from '../../../components/BlockedModal/hooks';
import { BlockModalTypes } from '../../../components/BlockedModal/types';
import useErrorModal from '../../../components/GenericModal/hooks/useErrorModal';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';

const useShared = (props: SharedViewModelProps): SharedViewModelReturnType => {
    const { isStartOver, goBack, onGoToNextEpisode, onFetchStream, onBlockedModalAction } = props;
    const { closePlayer: closePodcastPlayer, episodeInPlayer } = usePodcastPlayer();

    // States and Refs //
    const [startTime, setStartTime] = useState(0);
    const [blockingExternally, setBlockingExternally] = useState<boolean>(false);
    const [blockingReason, setBlockingReason] = useState<BLOCKING_REASON_TYPES | null>();
    const [blockingMessage, setBlockingMessage] = useState<undefined | string>();
    const [related, setRelated] = useState([]);
    const [playbackError, setPlaybackError] = useState<Error | IPlayerError | null>(null);
    const [startPosition, setStartPosition] = useState<undefined | number>(0);
    const [startPositionType, setStartPositionType] = useState<SeekType | undefined>(undefined);
    const [isCheckingBlockers, setCheckingBlockers] = useState(true);
    const [rawAsset, setRawAsset] = useState<Asset | Episode | Series | null>(null);
    const [asset, setAsset] = useState<Asset | Episode | Series | null>(null);
    const currentAssetRef = useRef<Asset | Episode | Series | undefined | null>(null);
    const [assetError, setAssetError] = useState<Error | PlayerError | null>(null);
    const blockerCheckedAsset = useRef<Asset | Episode | Series>();
    const [currentProgram, setCurrentProgram] = useState<Broadcast | null>(null);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const playerRef = useRef<PlayerBase<any> | null>(null);

    // Customs Hooks //
    useDefaultOptions(playerRef);
    const { engine } = usePlayerEngine();
    const analyticsDataClient = useAnalyticsData();
    const { events } = useUserData();
    const { openBlockedModal } = useBlockedModal();
    const { userData } = useStore();
    const { closeModal } = useModal();
    const { recordError, crashlyticsLog } = useFirebase();
    const { t } = useTranslation(['sott']);
    const queryClient = useQueryClient();
    const seriesData = useSeriesDataManager(rawAsset || undefined);
    const assetDuration = useRef(0);
    const { fetchAsset, fetchEpisodes, fetchRecommended } = useContentData();
    const { logMonitoringException } = useMonitoring();
    const { data: continueWatchingPlaylist } = useContinueWatchingQuery();
    const assetForBlockerCheck = currentProgram || asset || undefined;
    const { hasAgeBlocker, hasAdultBlocker, triggerPinControl } = useAssetPinControl({
        asset: assetForBlockerCheck,
        active: false,
        goBackAfterActivation: false,
        goBackAfterDismiss: false,
    });

    const { source, sourceError, getStream } = useSourceManager(
        asset || undefined,
        onFetchStream,
        Boolean(!isCheckingBlockers && !blockingExternally && !blockingReason && engine)
    );

    const shouldHaveContinueWatching = !asset?.isTrailer && asset?.type !== ASSET_TYPE.CLIP;

    const handleDurationChange = ({ duration }) => {
        if (duration) {
            assetDuration.current = duration;
        }
    };
    const setVideoPlayerRef = useCallback((ref) => {
        playerRef.current?.removeEventListener('durationchange', handleDurationChange);
        playerRef.current = ref;
        playerRef.current?.addEventListener('durationchange', handleDurationChange);
    }, []);
    const startTimeSet = useRef(false);

    useEffect(() => {
        // Update asset ref for events
        currentAssetRef.current = asset;
    }, [asset]);

    // Close podcast player, if open
    useEffect(() => {
        if (episodeInPlayer != null) {
            closePodcastPlayer();
        }
    }, [episodeInPlayer, closePodcastPlayer]);

    // Functions //
    const handleCloseErrorModal = (): void => {
        goBack();
    };

    const sendBackendEvents = async (
        assetId: string,
        assetType: string,
        action: string,
        offset: number
    ) => {
        try {
            await events(assetId, assetType, action, offset || 0);

            // If successful, invalidating queries to get updated assets' offsets from backend
            queryClient.invalidateQueries(QUERY_KEYS.playlists);
            queryClient.invalidateQueries(QUERY_KEYS.continueWatchingPlaylist);
        } catch (err) {
            recordError?.(err);
            log(err);
        }
    };

    const backstageAnalyticsLog = async (
        eventName: string,
        properties: BackStageAnalyticsProperties
    ): Promise<void> => {
        try {
            const token = await Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN);
            await analyticsDataClient.logAnalyticsEventToBackstage(
                eventName,
                { timestamp: Date.now(), ...properties },
                { key: token }
            );
        } catch (err) {
            recordError(err);
            log(err);
        }
    };

    const openBlockedModalOverride = (blocker?: BlockedItem | null) => {
        if (blocker?.reason === BLOCKING_REASON_TYPES.GEO_TYPE) {
            // Geoblock will be handled by player UI
            setBlockingReason(blocker.reason);
            return;
        }

        const handleCloseBlockModal = (): void => {
            closeModal();
            goBack();
        };

        const onActionOverride = () => {
            closeModal();
            onBlockedModalAction(blocker ?? undefined);
        };

        const blockedModalProps = {
            onModalClose: handleCloseBlockModal,
        };

        openBlockedModal(
            BlockModalTypes.ACCESS,
            t('error.E02.body') as string,
            blockedModalProps,
            handleCloseBlockModal,
            onActionOverride
        );
    };

    const { openErrorModal } = useErrorModal({ onCloseErrorModal: handleCloseErrorModal });

    const { handleBlockersCheck } = useAssetBlockersValidation({
        onGeoBlockOverride: openBlockedModalOverride,
        onAuthenticationBlockOverride: openBlockedModalOverride,
        onErrorOverride: () => openErrorModal(t('error.A00.title'), t('error.A00.body')),
    });

    const checkAssetBlockers = async (targetAsset): Promise<void> => {
        if (!targetAsset) {
            setCheckingBlockers(false);
            return;
        }

        const currentBlocker = blockerCheckedAsset.current;

        if (currentBlocker) {
            const isSameLiveChannel =
                currentBlocker.type === ASSET_TYPE.BROADCAST &&
                targetAsset.type === ASSET_TYPE.BROADCAST &&
                currentBlocker.channelId === targetAsset.channelId;

            if (!isSameLiveChannel) {
                setCheckingBlockers(true);
            }
        }

        if (
            (!hasAdultBlocker && blockingReason === BLOCKING_REASON_TYPES.ADULT) ||
            (!hasAgeBlocker && blockingReason === BLOCKING_REASON_TYPES.AGE)
        ) {
            setCheckingBlockers(false);
            setBlockingReason(null);
            setBlockingExternally(false);
            return;
        }
        const { hasBlocker, MVPCustomMessage } = await handleBlockersCheck({
            asset: targetAsset,
            openAPTModalOnBlock: false,
        });

        setCheckingBlockers(false);
        setBlockingExternally(hasBlocker);

        if (hasBlocker && MVPCustomMessage) {
            setBlockingReason(BLOCKING_REASON_TYPES.PRIMETIME_AUTHZ_ERR);
            setBlockingMessage(MVPCustomMessage);
            return;
        }

        if (hasAdultBlocker) {
            setBlockingReason(BLOCKING_REASON_TYPES.ADULT);
            return;
        }

        if (hasAgeBlocker) {
            setBlockingReason(BLOCKING_REASON_TYPES.AGE);
            // eslint-disable-next-line no-useless-return
            return;
        }
    };

    const getPlayPositionOverride = (targetAsset: Asset) => {
        if (isStartOver || !shouldHaveContinueWatching || targetAsset.isTrailer)
            return { continueWatchingOffset: 0 };
        if (userData && continueWatchingPlaylist) {
            return getContinueWatchingOffset(continueWatchingPlaylist, targetAsset.id);
        }
        return undefined;
    };

    const mapAndSetAsset = (targetAsset?: Asset) => {
        if (!targetAsset) {
            return;
        }
        let nextSelectedAsset = targetAsset;
        const assetPlayPositionOverride = getPlayPositionOverride(nextSelectedAsset);

        if (nextSelectedAsset?.isTrailer && nextSelectedAsset.type !== ASSET_TYPE.CLIP) {
            nextSelectedAsset.title = t('asset.tab.trailer');
            nextSelectedAsset.subtitle = '';
        }

        if (assetPlayPositionOverride) {
            nextSelectedAsset = {
                ...nextSelectedAsset,
                ...assetPlayPositionOverride,
            };
        }

        if (isStartOver) {
            nextSelectedAsset = {
                ...nextSelectedAsset,
                ...(isStartOver && { isStartOver: isStartOver === true }),
            };
        }

        setRawAsset(targetAsset);
        setAsset(nextSelectedAsset);
        if (nextSelectedAsset.isLive) {
            if (
                nextSelectedAsset.startsAt &&
                nextSelectedAsset.continueWatchingOffset === 0 &&
                isStartOver
            ) {
                // Startover logic calculation
                setStartPosition(nextSelectedAsset.startsAt * 1000);
                setStartPositionType(SeekType.UTC);
            } else {
                // Start from live edge
                setStartPosition(0);
                setStartPositionType(SeekType.TIMESHIFT);
            }
        } else {
            // VOD
            const continueWatchingMilliseconds =
                (nextSelectedAsset.continueWatchingOffset || 0) * 1000;
            if (isStartOver) {
                // start from beginning
                setStartPosition(0);
                setStartPositionType(SeekType.CURRENT_TIME);
            } else if (continueWatchingMilliseconds > 0) {
                // continue watching from last position
                setStartPosition(continueWatchingMilliseconds);
                setStartPositionType(SeekType.CONTENT_TIME);
            } else {
                // start from "default" position, in case it is live stream in disguise
                setStartPosition(0);
                setStartPositionType(SeekType.TIMESHIFT);
            }
        }
    };

    const fetchRelated = async (targetAsset?: Asset) => {
        /*
         * "Related" should be there only for VOD Movies
         * https://jira.24i.com/browse/PRDPLAYER-1076
         */
        if (targetAsset?.type !== ASSET_TYPE.MOVIE) {
            setRelated([]);
            return;
        }
        const { id, type } = targetAsset;
        const relatedItems = await fetchRecommended(id, type);

        setRelated(relatedItems);
    };

    const fetchPlaybackData = async (): Promise<Asset | Episode | Series | undefined> => {
        try {
            const { assetId, assetType, channelId, editionId, isTrailer, sectionLabel } = props;
            const fetchedAsset: Asset | undefined = await fetchAsset({
                id: assetId,
                type: assetType,
                channelId,
            });

            if (!fetchedAsset) {
                return undefined;
            }

            if (isEpisode(fetchedAsset) && fetchedAsset.seriesId) {
                const seasons = await fetchEpisodes(fetchedAsset.seriesId, t);
                return { ...fetchedAsset, seasons };
            }
            return {
                ...fetchedAsset,
                ...(editionId && { editionId }),
                ...(isTrailer !== undefined && { isTrailer }),
                ...(sectionLabel && { sectionLabel }),
            };
        } catch (e) {
            log(`Error fetching asset data in playback screen`, e);
            setAssetError(e as Error);
            return undefined;
        }
    };

    const loadPlaybackData = async () => {
        let newAsset;
        if (!props.asset && (!props.assetId || !props.assetType)) {
            logError(
                `We don't have asset, but also we don't have a way to fetch it with the given info. Check the navigation info that is being sent`
            );
            newAsset = undefined;
        } else if (props.asset) {
            newAsset = props.asset;
        } else {
            newAsset = await fetchPlaybackData();
        }
        mapAndSetAsset(newAsset);
    };

    // Player Actions //

    const onPlay = async (): Promise<void> => {
        const playerInstance = playerRef?.current;
        if (!playerInstance) return;
        const streamTime = playerInstance.currentTime;
        const streamDuration = playerInstance.duration;
        const contentTime = playerInstance.getContentTime(streamTime);
        const contentDuration = playerInstance.getContentTime(streamDuration);
        const currentAsset = currentAssetRef.current;
        const assetId = currentAsset?.id;
        const assetType = currentAsset?.type;
        const action = 'play';
        try {
            if (userData) {
                if (!startTimeSet.current && currentAsset?.continueWatchingOffset) {
                    const offset = shouldHaveContinueWatching
                        ? calculateEventOffset(
                              currentAsset.continueWatchingOffset,
                              currentAsset.duration,
                              contentDuration
                          )
                        : 0;

                    setStartTime((): number => {
                        startTimeSet.current = true;
                        return offset as number;
                    });
                    return;
                }
                const offset = calculateEventOffset(
                    contentTime,
                    contentDuration,
                    currentAsset?.duration
                );
                if (assetId && assetType) {
                    const finalOffset = offset || 0;
                    const hasAds = playerInstance?.timeline?.filter(
                        (e) => e.type === ISourceTimelineEventType.ADVERTISEMENT
                    )?.length;
                    if (streamTime && (!playerInstance?.timeline || !hasAds)) {
                        await sendBackendEvents(assetId, assetType, action, finalOffset);
                    }
                }
            }

            await backstageAnalyticsLog('playbackStart', {
                mediaId: asset?.id || '',
                playbackTimeInSeconds: streamTime || 0,
                offset: startTime || 0,
            });
        } catch (err) {
            recordError(err);
            log(err);
        }
    };

    const onPause = async (): Promise<void> => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        if (playerInstance && userData && asset?.duration) {
            const streamTime = playerInstance.currentTime;
            const streamDuration = playerInstance.duration;
            const contentTime = playerInstance.getContentTime(streamTime);
            const contentDuration = playerInstance.getContentTime(streamDuration);
            const assetId = currentAsset?.id;
            const assetType = currentAsset?.type;
            const action = 'pause';
            const offset = calculateEventOffset(contentTime, contentDuration, asset.duration);

            if (offset && shouldHaveContinueWatching && assetId && assetType) {
                await sendBackendEvents(assetId, assetType, action, offset);
            }
        }
    };

    const onStop = async (): Promise<void> => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        const streamTime = playerInstance?.currentTime;
        const streamDuration = assetDuration.current;
        const action = 'stop';
        if (playerInstance && streamTime && streamDuration) {
            try {
                const contentTime = playerInstance.getContentTime(streamTime);
                const contentDuration = playerInstance.getContentTime(streamDuration);

                const streamEnded = streamTime >= streamDuration;

                if (userData && currentAsset?.duration && shouldHaveContinueWatching) {
                    const assetId = currentAsset.id;
                    const assetType = currentAsset.type;
                    let offset = calculateEventOffset(
                        contentTime,
                        contentDuration,
                        currentAsset.duration
                    );

                    if (offset === currentAsset.duration) offset = 0;
                    if (streamEnded) offset = currentAsset.duration;
                    if (offset) {
                        await sendBackendEvents(assetId, assetType, action, offset);

                        if (streamEnded) {
                            const nextEpisode = seriesData?.nextEpisode;
                            // this adds the next episode into the continue watching list once the previous one ended
                            if (isEpisode(currentAsset) && currentAsset.nextEpisodeId) {
                                await sendBackendEvents(
                                    currentAsset.nextEpisodeId,
                                    ASSET_TYPE.EPISODE,
                                    action,
                                    0
                                );
                            } else if (nextEpisode) {
                                await sendBackendEvents(
                                    nextEpisode.id,
                                    nextEpisode.type,
                                    action,
                                    0
                                );
                            }
                        }
                    }

                    startTimeSet.current = false;
                    // Send backstage analytics event
                    await backstageAnalyticsLog('playbackStop', {
                        mediaId: currentAsset.id || '',
                        playbackTimeInSeconds: streamTime,
                    });
                }
            } catch (err) {
                recordError(err);
                log(err);
            }
        }

        queryClient.invalidateQueries(QUERY_KEYS.continueWatchingPlaylist);
        queryClient.invalidateQueries(QUERY_KEYS.lastWatchPlaylist);
    };

    const onEnd = async (): Promise<void> => {
        // Handle behaviours when stream ends
        await onStop();
    };

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const onSeeking = async (_event) => {
        // Empty for now. You can read event.time
    };

    const onSeeked = async () => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        if (playerInstance && isPlatformWeb) {
            const streamTime = playerInstance.currentTime;
            const streamDuration = playerInstance.duration;
            const contentTime = playerInstance.getContentTime(streamTime);
            const contentDuration = playerInstance.getContentTime(streamDuration);
            const assetId = currentAsset?.id;
            const assetType = currentAsset?.type;
            const action = 'play';
            const offset = calculateEventOffset(
                contentTime,
                contentDuration,
                currentAsset?.duration || 0
            );

            if (offset && assetId && assetType && shouldHaveContinueWatching) {
                await sendBackendEvents(assetId, assetType, action, offset);
            }
        }
    };

    const onUnlockAction = () => {
        triggerPinControl();
    };

    const onPlaybackError = useCallback(
        (error: IPlayerError | Error) => {
            if ('code' in error) {
                // avoid the stop popup when error is typical shaka 7000.0 (not breakable)
                if (error.engine === 'shaka-player' && error.nativeCode === 'LOAD_INTERRUPTED')
                    return;
            }

            setPlaybackError(error);

            if (error instanceof PlayerError) {
                const context: any = {
                    code: error.code,
                    source: JSON.stringify(error.source, undefined, 2),
                    engine: error.engine ?? 'unknown',
                    engineVersion: error.engineVersion ?? '',
                    nativeCode: error.nativeCode ?? 'unknown',
                };
                logMonitoringException(error, context);
            } else if (error instanceof Error) {
                logMonitoringException(error);
            }
        },
        [setPlaybackError]
    );

    const { errorMessage } = useErrorHandling({
        sourceError,
        playbackError,
        assetError,
        onError: onPlaybackError,
    });

    // Use Effects //

    useEffect(() => {
        if (assetForBlockerCheck) checkAssetBlockers(assetForBlockerCheck);
    }, [assetForBlockerCheck, userData, hasAgeBlocker, hasAdultBlocker]);

    useEffect(() => {
        return () => playerRef.current?.removeEventListener('durationchange', handleDurationChange);
    }, []);

    useUpdateEffect(() => setCurrentProgram(null), [asset]);

    useEffect(() => {
        crashlyticsLog('PlaybackScreen entered');
        return crashlyticsLog('PlaybackScreen exited');
    }, []);

    useEffect(() => {
        // Reset on asset change
        setAsset(null);
        setPlaybackError(null);
        setBlockingReason(null);
        setBlockingMessage(undefined);
        setBlockingExternally(false);
        setStartPosition(undefined);
        setStartPositionType(undefined);
        setRelated([]);
        loadPlaybackData();
    }, [props.asset, props.assetId, props.assetType]);

    useEffect(() => {
        fetchRelated(rawAsset || undefined);
    }, [rawAsset]);

    const isCatchUp =
        asset?.isLive === false &&
        asset?.type !== undefined &&
        [ASSET_TYPE.CHANNEL, ASSET_TYPE.EPG, ASSET_TYPE.BROADCAST].includes(asset.type);
    const shouldShowAirtime = isCatchUp;

    return {
        ...props,
        autoPlay: true,
        nativeControls: false,
        asset: asset || undefined,
        seriesData,
        startPosition,
        startPositionType,
        engine,
        startTime,
        source,
        setBlockingReason,
        blockingReason,
        blockingMessage,
        related,
        errorMessage,
        isCheckingBlockers,
        shouldShowAirtime,
        onPlay,
        onPause,
        onEnd,
        onStop,
        onSeeking,
        onSeeked,
        onError: onPlaybackError,
        onFetchStream,
        getStream,
        onCloseErrorModal: handleCloseErrorModal,
        onUnlockAction,
        onCurrentProgramChange: setCurrentProgram,
        setVideoPlayerRef,
        onGoToNextEpisode: onGoToNextEpisode
            ? (currentAsset) => onGoToNextEpisode(currentAsset, seriesData)
            : undefined,
    };
};

export { useShared };
