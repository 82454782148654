import { isPlatformWeb } from 'renative';
import {
    Asset,
    ASSET_TYPE,
    getIsPodcastEpisode,
    getIsPodcastSeries,
    PageSection,
    Season,
} from '@24i/nxg-sdk-photon';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import { useCast } from '@24i/nxg-sdk-smartott/src/context/Cast';
import prepareAutoPlay from '@24i/nxg-sdk-smartott/src/utils/prepareAutoPlay';

import { useSourceManager } from '../../PlaybackScreen/managers';
import useAssetActions from '../../../hooks/useAssetActions';
import { useStore } from '../../../context/ApplicationStore';
import { CustomAsset, DynaRowSharedProps, DynaRowScreenProps } from '../types';
import useSectionsStructureQuery from '../../../hooks/query/dynarow/useSectionsStructureQuery';
import usePlaylistsQuery from '../../../hooks/query/dynarow/usePlaylistsQuery';
import useAssetBlockersValidation from '../../../hooks/useAssetBlockersValidation';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';

const PLAYLISTS_MOBILE_PAGE_SIZE = 5;

const useShared = ({
    onFetchStream,
    goToPlayer: customGoToPlayer,
    pageId = '',
    sections: sectionsFromProps,
    hasScreenType,
}: DynaRowScreenProps): DynaRowSharedProps => {
    const { selectedProfile } = useStore();
    const { getStream } = useSourceManager(undefined, onFetchStream);

    const {
        startPlayback,
        isCanGoToPlayer,
        isCanGoToDetail,
        getEpisodeToWatch,
        fetchEpisodeToReproduce,
    } = useAssetActions();
    const { isCastConnected, startCastingStream } = useCast();
    const { handleBlockersCheck } = useAssetBlockersValidation({});
    const { fetchEpisodes } = useContentData();
    const {
        closePlayer: closePodcastPlayer,
        startLoading: startLoadingPodcastPlayer,
        stopLoading: stopLoadingPodcastPlayer,
        episodeInPlayer: podcastEpisodeInPlayer,
    } = usePodcastPlayer();

    // If sections are delivered from props, there is no need to fetch data through pageId. Placing it an empty string will disable this and following request.
    const shouldFetchData = !sectionsFromProps;
    const pageIdToFetch = shouldFetchData ? pageId : '';

    const {
        data: pageSections,
        isError: isPageSectionsError,
        isLoading: arePageSectionsLoading,
        isIdle: arePageSectionsIdle,
    } = useSectionsStructureQuery(pageIdToFetch, selectedProfile);

    // TODO - Legacy logic, why do we have different behavior for web and mobile?
    const playListSize = isPlatformWeb ? pageSections?.length : PLAYLISTS_MOBILE_PAGE_SIZE;

    const {
        data: sectionsWithPlaylistsPages,
        fetchNextPage,
        refetch,
        isError: isPlaylistsError,
        isLoading: isPlaylistLoading,
        isIdle: isPlaylistIdle,
        isRefetching,
        isFetching,
    } = usePlaylistsQuery(pageSections || [], pageId, playListSize, selectedProfile, hasScreenType);

    const sectionsFromQuery = sectionsWithPlaylistsPages?.pages
        ?.flat()
        .filter((section) => !!section?.items?.length || !!section?.additionalItems?.length);

    const sections: PageSection[] | undefined = sectionsFromProps || sectionsFromQuery;

    const isDataLoading =
        shouldFetchData &&
        (arePageSectionsLoading ||
            arePageSectionsIdle ||
            isPlaylistLoading ||
            isPlaylistIdle ||
            (!hasScreenType && isRefetching && isFetching));

    const handleGoToPlayer = async (
        item: Asset | CustomAsset,
        handlePurchaseAsset?: (asset: Asset) => void,
        playPodcastFromDetailPage?: boolean,
        redirectToPodcastDetailPage?: (item: Asset) => void
    ) => {
        if (customGoToPlayer) {
            customGoToPlayer(item);
            return;
        }

        const isPodcastSeries = getIsPodcastSeries(item);
        const isPodcastEpisode = getIsPodcastEpisode(item);
        const shouldFollowPodcastFlow = isPodcastSeries || isPodcastEpisode;

        const assetToCheck = isPodcastSeries ? await fetchEpisodeToReproduce(item, 'latest') : item;

        const { hasBlocker, MVPCustomMessage } = await handleBlockersCheck({
            asset: assetToCheck ?? item,
            handlePurchase: handlePurchaseAsset,
            openAPTModalOnBlock: false,
        });

        if (hasBlocker && !MVPCustomMessage) {
            if (shouldFollowPodcastFlow) closePodcastPlayer();
            return; // allow Adobe Primetime block cases to arrive to playbackScreen and show modal there
        }

        if (shouldFollowPodcastFlow) {
            const episodeIsAlreadyInPodcastPlayer = podcastEpisodeInPlayer?.id === assetToCheck?.id;
            if (episodeIsAlreadyInPodcastPlayer) {
                return;
            }

            if (
                isPlatformWeb &&
                playPodcastFromDetailPage &&
                redirectToPodcastDetailPage &&
                assetToCheck &&
                getIsPodcastEpisode(assetToCheck)
            ) {
                redirectToPodcastDetailPage(item);
                return;
            }

            closePodcastPlayer();
            startLoadingPodcastPlayer();
        }

        if (isCastConnected) {
            const sourceData = await getStream(item);
            await startCastingStream(item, sourceData);
        } else {
            if (shouldFollowPodcastFlow) stopLoadingPodcastPlayer();
            startPlayback(item);
        }
    };

    const handleSeriesPlayerAction = async (
        show: Asset,
        handlePurchaseAsset?: (asset: Asset) => void
    ) => {
        const seasons: Season[] = await fetchEpisodes(show.id);
        let episode;
        let seriesTitle;
        let episodeWithSeriesTitle;
        if (!show.isTrailer) {
            episode = getEpisodeToWatch(seasons || []);
            seriesTitle = show.title;
            episodeWithSeriesTitle = {
                ...episode,
                seriesTitle,
            };
        }
        handleGoToPlayer(episodeWithSeriesTitle, handlePurchaseAsset);
    };

    const onPackshotPress = async ({
        onLinkPress,
        onGoToDetails,
        section,
        asset,
        onPurchaseAsset,
        playPodcastFromDetailPage,
        redirectToPodcastDetailPage,
    }: {
        onLinkPress: () => void;
        onGoToDetails: () => void;
        section: PageSection;
        asset: Asset;
        onPurchaseAsset?: (asset: Asset) => void;
        playPodcastFromDetailPage?: boolean;
        redirectToPodcastDetailPage?: (item: Asset) => void;
    }) => {
        if ([ASSET_TYPE.CUSTOM, ASSET_TYPE.IMAGE].includes(asset.type)) {
            onLinkPress();
        } else if (section.actionForAllItems === 'player' && asset.type === ASSET_TYPE.SERIES) {
            await prepareAutoPlay();
            handleSeriesPlayerAction(asset, onPurchaseAsset);
        } else if (
            (section.actionForAllItems === 'player' && isCanGoToPlayer(asset)) ||
            (section.actionForAllItems === 'detail' && !isCanGoToDetail(asset))
        ) {
            await prepareAutoPlay();
            handleGoToPlayer(
                asset,
                onPurchaseAsset,
                playPodcastFromDetailPage,
                redirectToPodcastDetailPage
            );
        } else {
            onGoToDetails();
        }
    };

    return {
        handleGoToPlayer,
        fetchNextPage: () => fetchNextPage(),
        refetch: () => refetch(),
        onPackshotPress,
        hasError: isPageSectionsError || isPlaylistsError,
        sections,
        isLoading: isDataLoading,
        shouldFetchData,
    };
};

export default useShared;
