import { createGuard, nullable } from '@24i/nxg-core-utils/src/guards';
import { EditionGuard, ProductGuard } from '@24i/nxg-sdk-photon';
import * as t from 'io-ts';

export enum SubscriptionStatus {
    ACTIVE = 'active',
    INACTIVE = 'inactive',
    CANCELLED = 'cancelled',
}

export const ProductsResponseGuard = createGuard('ProductsResponse', t.array(ProductGuard));

export const SubscriptionProductResponseGuard = createGuard('SubscriptionProductsResponse', {
    id: t.string,
    name: t.string,
    price: t.number,
    currency: t.string,
    description: nullable(t.string),
    externalAccessType: t.string,
    externalAccessValue: t.string,
    active: t.boolean,
    type: t.string,
    paymentMethod: nullable(t.string),
    billingFrequency: t.type({
        unit: t.string,
        count: t.number,
    }),
});

export const ExternalCheckoutResponseGuard = createGuard('ExternalCheckoutResponse', {
    url: t.string,
});
export const EditionsResponseGuard = createGuard('EditionsResponse', {
    editions: t.array(EditionGuard),
});

export const ProcessReceiptResponseGuard = createGuard('ProcessReceiptResponse', {});
