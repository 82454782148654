/* eslint-disable max-statements */
import { useState, useCallback, useRef, useEffect, useMemo } from 'react';
import { isPlatformWeb } from 'renative';
import { useQueryClient } from 'react-query';
// DONT CHANGE IMPORT PATHS BELOW OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useUserData } from '@24i/nxg-sdk-smartott-shared/src/context/UserData';
import { useAnalyticsData } from '@24i/nxg-sdk-smartott-shared/src/context/AnalyticsData';
import { useStore } from '@24i/nxg-sdk-smartott/src/context/ApplicationStore';
import { useFirebase } from '@24i/nxg-sdk-smartott/src/context/Firebase';
import { ASYNC_STORAGE_KEY_USER_TOKEN } from '@24i/nxg-core-utils/src/constants';
import { log } from '@24i/nxg-core-utils/src/logger';
import { Storage } from '@24i/nxg-sdk-quantum';
import { useContentData } from '@24i/nxg-sdk-smartott-shared/src/context/ContentData';
import {
    IPlayerError,
    PlayerBase,
    PlayerError,
    SeekType,
    ISourceTimelineEventType,
} from '@24i/player-base';
import {
    Asset,
    ASSET_TYPE,
    Episode,
    QUERY_KEYS,
    Series,
    PodcastEpisode,
    getIsPodcastEpisode,
} from '@24i/nxg-sdk-photon';
import { useTranslation } from 'react-i18next';
// DONT CHANGE IMPORT PATHS ABOVE OR NEXT WILL STOP BUILDING ROUTES CORRECTLY
import { useMonitoring } from '@24i/nxg-sdk-smartott-shared/src/context/MonitoringData';
import { useSourceManager } from '../../../screens/PlaybackScreen/managers';
import { useDefaultOptions } from '../../../screens/PlaybackScreen/hooks/useDefaultOptions';
import useContinueWatchingQuery, {
    getContinueWatchingOffset,
} from '../../../hooks/query/continueWatching/useContinueWatchingQuery';
import { usePlayerEngine } from '../../../context/PlayerEngine';
import { BackStageAnalyticsProperties } from '../../../screens/PlaybackScreen/types';
import { calculateEventOffset, handlePlaybackError } from '../../../screens/PlaybackScreen/helpers';
import { usePodcastPlayer } from '../../../context/PodcastPlayerProvider';
import { AdjacentEpisodes, OnPrevNextPress, PodcastPlayerViewProps } from '../types';
import { useErrorHandling } from '../../../screens/PlaybackScreen/hooks/useErrorHandling';
import useBlockedModal from '../../BlockedModal/hooks';
import useErrorModal from '../../GenericModal/hooks/useErrorModal';

export const useShared = (): PodcastPlayerViewProps => {
    const {
        onPlaybackResumed,
        onPlaybackPaused,
        episodeInPlayer,
        playPodcastEpisode,
        closePlayer,
        startLoading,
        stopLoading,
        startPreparingEpisode,
        stopPreparingEpisode,
        playerInstanceHandle,
    } = usePodcastPlayer();
    const { openBlockedModal } = useBlockedModal();
    const { openErrorModal } = useErrorModal({});
    const contentDataClient = useContentData();
    const { t } = useTranslation(['sott']);

    // States and Refs //
    const [startTime, setStartTime] = useState(0);
    const [playbackError, setPlaybackError] = useState<Error | IPlayerError | null>(null);
    const [startPosition, setStartPosition] = useState<undefined | number>(undefined);
    const [startPositionType, setStartPositionType] = useState<SeekType | undefined>(undefined);
    const [asset, setAsset] = useState<PodcastEpisode | null>(episodeInPlayer);
    const [allSeriesEpisodes, setAllSerieEpisodes] = useState<PodcastEpisode[]>([]);

    const currentAssetRef = useRef<Asset | Episode | Series | undefined | null>(null);

    const adjacentEpisodes: AdjacentEpisodes = useMemo(() => {
        const currentIndex = allSeriesEpisodes.findIndex((ep) => ep.id === asset?.id);
        const prev = currentIndex > 0 ? allSeriesEpisodes[currentIndex - 1] : null;
        const previousEpisode = prev && getIsPodcastEpisode(prev) ? prev : null;

        const next =
            currentIndex < allSeriesEpisodes.length - 1
                ? allSeriesEpisodes[currentIndex + 1]
                : null;

        const nextEpisode = next && getIsPodcastEpisode(next) ? next : null;

        return { previousEpisode, nextEpisode };
    }, [asset, allSeriesEpisodes, getIsPodcastEpisode]);

    const fetchAllSeriesEpisodes = useCallback(
        async (currentEpisode: PodcastEpisode) => {
            const seasonsData = await contentDataClient.fetchEpisodes(
                currentEpisode.seriesId || currentEpisode.series,
                t,
                ASSET_TYPE.PODCAST_SERIES
            );
            const allEpisodes = seasonsData.flatMap((season) => season.episodes);
            if (allEpisodes) setAllSerieEpisodes(allEpisodes);
        },
        [asset?.seriesId, t]
    );

    const playerRef = useRef<PlayerBase<any> | null>(null);
    const startTimeSet = useRef(false);

    // Customs Hooks //

    useDefaultOptions(playerRef);
    const { engine } = usePlayerEngine();
    const analyticsDataClient = useAnalyticsData();
    const { events } = useUserData();
    const { userData } = useStore();
    const queryClient = useQueryClient();
    const { recordError, crashlyticsLog } = useFirebase();
    const assetDuration = useRef(0);
    const { logMonitoringException } = useMonitoring();
    const { data: continueWatchingPlaylist } = useContinueWatchingQuery();

    const { source, sourceError } = useSourceManager(
        asset || undefined,
        undefined,
        Boolean(!!asset && engine)
    );

    const shouldHaveContinueWatching = !asset?.isTrailer && asset?.type !== ASSET_TYPE.CLIP;
    const playerIsLoadingInternally = useMemo(
        () => startPosition === undefined || !engine || !source,
        [startPosition, engine, source]
    );

    // Functions //

    const handleDurationChange = ({ duration }) => {
        if (duration) {
            assetDuration.current = duration;
        }
    };

    const sendBackendEvents = async (
        assetId: string,
        assetType: string,
        action: string,
        offset: number
    ) => {
        try {
            await events(assetId, assetType, action, offset || 0);

            // If successful, invalidating queries to get updated assets' offsets from backend
            queryClient.invalidateQueries(QUERY_KEYS.playlists);
            queryClient.invalidateQueries(QUERY_KEYS.continueWatchingPlaylist);
        } catch (err) {
            recordError?.(err);
            log(err);
        }
    };

    const backstageAnalyticsLog = async (
        eventName: string,
        properties: BackStageAnalyticsProperties
    ): Promise<void> => {
        try {
            const token = await Storage.getItem(ASYNC_STORAGE_KEY_USER_TOKEN);
            await analyticsDataClient.logAnalyticsEventToBackstage(
                eventName,
                { timestamp: Date.now(), ...properties },
                { key: token }
            );
        } catch (err) {
            recordError(err);
            log(err);
        }
    };

    const onPrevNextPress: OnPrevNextPress = useCallback(
        async (direction) => {
            startLoading();
            const adjacentEpisode =
                direction === 'prev'
                    ? adjacentEpisodes.previousEpisode
                    : adjacentEpisodes?.nextEpisode;

            if (!adjacentEpisode) {
                stopLoading();
                return;
            }

            playPodcastEpisode(adjacentEpisode);
        },
        [adjacentEpisodes.previousEpisode, adjacentEpisodes?.nextEpisode]
    );

    const getStartPosition = useCallback(
        (targetAsset: Asset): number => {
            if (!shouldHaveContinueWatching || targetAsset.isTrailer) return 0;
            if (userData && continueWatchingPlaylist) {
                const offset = getContinueWatchingOffset(continueWatchingPlaylist, targetAsset.id);

                return offset?.continueWatchingOffset ?? 0;
            }
            return 0;
        },
        [shouldHaveContinueWatching, userData, continueWatchingPlaylist, getContinueWatchingOffset]
    );

    // Player Actions //

    const onPlay = async (): Promise<void> => {
        onPlaybackResumed();
        const playerInstance = playerRef?.current;
        if (!playerInstance) return;
        const streamTime = playerInstance.currentTime;
        const streamDuration = playerInstance.duration;
        const contentTime = playerInstance.getContentTime(streamTime);
        const contentDuration = playerInstance.getContentTime(streamDuration);
        const currentAsset = currentAssetRef.current;
        const assetId = currentAsset?.id;
        const assetType = currentAsset?.type;
        const action = 'play';
        try {
            if (userData) {
                if (!startTimeSet.current && currentAsset?.continueWatchingOffset) {
                    const offset = shouldHaveContinueWatching
                        ? calculateEventOffset(
                              currentAsset.continueWatchingOffset,
                              currentAsset.duration,
                              contentDuration
                          )
                        : 0;

                    setStartTime((): number => {
                        startTimeSet.current = true;
                        return offset as number;
                    });
                    return;
                }
                const offset = calculateEventOffset(
                    contentTime,
                    contentDuration,
                    currentAsset?.duration
                );
                if (assetId && assetType) {
                    const finalOffset = offset || 0;
                    const hasAds = playerInstance?.timeline?.filter(
                        (e) => e.type === ISourceTimelineEventType.ADVERTISEMENT
                    )?.length;
                    if (streamTime && (!playerInstance?.timeline || !hasAds)) {
                        await sendBackendEvents(assetId, assetType, action, finalOffset);
                    }
                }
            }

            await backstageAnalyticsLog('playbackStart', {
                mediaId: asset?.id || '',
                playbackTimeInSeconds: streamTime || 0,
                offset: startTime || 0,
            });
        } catch (err) {
            recordError(err);
            log(err);
        }
    };

    const onPause = async (): Promise<void> => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        onPlaybackPaused();
        if (playerInstance && userData && asset?.duration) {
            const streamTime = playerInstance.currentTime;
            const streamDuration = playerInstance.duration;
            const contentTime = playerInstance.getContentTime(streamTime);
            const contentDuration = playerInstance.getContentTime(streamDuration);
            const assetId = currentAsset?.id;
            const assetType = currentAsset?.type;
            const action = 'pause';
            const offset = calculateEventOffset(contentTime, contentDuration, asset.duration);

            if (offset && shouldHaveContinueWatching && assetId && assetType) {
                await sendBackendEvents(assetId, assetType, action, offset);
            }
        }
    };

    const onStop = useCallback(async (): Promise<void> => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        const streamTime = playerInstance?.currentTime;
        const streamDuration = assetDuration.current;

        const action = 'stop';
        if (playerInstance && streamTime && streamDuration) {
            try {
                const contentTime = playerInstance.getContentTime(streamTime);
                const contentDuration = playerInstance.getContentTime(streamDuration);

                const streamEnded = streamTime >= streamDuration;

                if (userData && currentAsset?.duration && shouldHaveContinueWatching) {
                    const assetId = currentAsset.id;
                    const assetType = currentAsset.type;
                    let offset = calculateEventOffset(
                        contentTime,
                        contentDuration,
                        currentAsset.duration
                    );

                    if (offset === currentAsset.duration) offset = 0;
                    if (streamEnded) {
                        offset = currentAsset.duration;
                    }
                    if (offset) {
                        await sendBackendEvents(assetId, assetType, action, offset);

                        if (streamEnded && adjacentEpisodes.nextEpisode) {
                            await sendBackendEvents(
                                adjacentEpisodes.nextEpisode.id,
                                ASSET_TYPE.EPISODE,
                                action,
                                0
                            );
                        }
                    }

                    startTimeSet.current = false;
                    // Send backstage analytics event
                    await backstageAnalyticsLog('playbackStop', {
                        mediaId: currentAsset.id || '',
                        playbackTimeInSeconds: streamTime,
                    });
                }
            } catch (err) {
                recordError(err);
                log(err);
            }
        }

        queryClient.invalidateQueries(QUERY_KEYS.continueWatchingPlaylist);
        queryClient.invalidateQueries(QUERY_KEYS.lastWatchPlaylist);
    }, [
        currentAssetRef,
        playerRef,
        assetDuration,
        userData,
        shouldHaveContinueWatching,
        adjacentEpisodes.nextEpisode,
        sendBackendEvents,
        calculateEventOffset,
        startTimeSet,
        backstageAnalyticsLog,
        queryClient,
    ]);

    const onEnd = async (): Promise<void> => {
        // Handle behaviours when stream ends
        await onStop();
    };

    const onSeeked = async () => {
        const currentAsset = currentAssetRef.current;
        const playerInstance = playerRef?.current;
        if (playerInstance && isPlatformWeb) {
            const streamTime = playerInstance.currentTime;
            const streamDuration = playerInstance.duration;
            const contentTime = playerInstance.getContentTime(streamTime);
            const contentDuration = playerInstance.getContentTime(streamDuration);
            const assetId = currentAsset?.id;
            const assetType = currentAsset?.type;
            const action = 'play';
            const offset = calculateEventOffset(
                contentTime,
                contentDuration,
                currentAsset?.duration || 0
            );

            if (offset && assetId && assetType && shouldHaveContinueWatching) {
                await sendBackendEvents(assetId, assetType, action, offset);
            }
        }
    };

    const onPlaybackError = useCallback(
        (error: IPlayerError | Error) => {
            if ('code' in error) {
                // avoid the stop popup when error is typical shaka 7000.0 (not breakable)
                if (error.engine === 'shaka-player' && error.nativeCode === 'LOAD_INTERRUPTED')
                    return;
            }

            setPlaybackError(error);

            if (error instanceof PlayerError) {
                // @ts-ignore
                const context: any = {
                    code: error.code,
                    source: JSON.stringify(error.source, undefined, 2),
                    engine: error.engine ?? 'unknown',
                    engineVersion: error.engineVersion ?? '',
                    nativeCode: error.nativeCode ?? 'unknown',
                };
                logMonitoringException(error, context);
            } else if (error instanceof Error) {
                logMonitoringException(error);
            }
        },
        [setPlaybackError]
    );

    const { errorMessage } = useErrorHandling({
        sourceError,
        playbackError,
        assetError: undefined,
        onError: onPlaybackError,
    });

    // Use Effects //
    useEffect(() => {
        if (episodeInPlayer && episodeInPlayer.id !== asset?.id) {
            setAsset(episodeInPlayer);
        }
    }, [episodeInPlayer, episodeInPlayer?.id, asset?.id]);

    useEffect(() => {
        if (asset) {
            fetchAllSeriesEpisodes(asset);
        }
    }, [asset, fetchAllSeriesEpisodes]);

    useEffect(() => {
        // Reset on asset change
        setPlaybackError(null);
        setStartPosition(undefined);
        setStartPositionType(undefined);
    }, [asset, asset?.id]);

    useEffect(() => {
        // Update asset ref for events
        currentAssetRef.current = asset;
    }, [asset]);

    useEffect(() => {
        // Pass player handle to playerRef
        if (playerInstanceHandle) playerRef.current = playerInstanceHandle;
    }, [playerInstanceHandle]);

    useEffect(() => {
        // Handle loading while fetching source, startTime, etc
        if (playerIsLoadingInternally) {
            startPreparingEpisode();
        } else {
            stopPreparingEpisode();
        }
    }, [playerIsLoadingInternally, startPreparingEpisode, stopPreparingEpisode]);

    useEffect(() => {
        if (errorMessage) {
            setAsset(null);
            closePlayer();
            handlePlaybackError(
                errorMessage,
                openBlockedModal,
                undefined,
                openErrorModal,
                undefined
            );
        }
    }, [errorMessage?.title, errorMessage?.body]);

    useEffect(() => {
        // Bind durationchange event to playerHandle
        playerRef.current?.addEventListener('durationchange', handleDurationChange);
        return () => playerRef.current?.removeEventListener('durationchange', handleDurationChange);
    }, [playerRef?.current]);

    useEffect(() => {
        crashlyticsLog('Podcast-player opened');
        return crashlyticsLog('Podcast-player closed');
    }, []);

    useEffect(() => {
        // set startPosition
        if (asset) {
            const startPositionOverride = getStartPosition(asset);
            setStartPosition(startPositionOverride * 1000);
            setStartPositionType(
                startPositionOverride > 0 ? SeekType.CONTENT_TIME : SeekType.CURRENT_TIME
            );
        }
    }, [asset, getStartPosition]);

    return {
        asset: asset || undefined,
        startPosition,
        startPositionType,
        engine,
        source,
        onPlay,
        onPause,
        onEnd,
        onStop,
        onSeeked,
        onError: onPlaybackError,
        onPrevNextPress,
        adjacentEpisodes,
    };
};
