import { useEffect, useRef } from 'react';
import { useRouter } from 'next/router';

export const usePreviousRoute = () => {
    const { asPath } = useRouter();

    const prevUrlRef = useRef<string | null>(null);

    useEffect(() => {
        prevUrlRef.current = asPath;
    }, [asPath]);

    return prevUrlRef.current;
};
