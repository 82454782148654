/*
@PRODUCT_FEATURE: H 1.2, H 1.3.0, H 1.4
*/
/* eslint-disable react/display-name */
import React, { useEffect } from 'react';
import { BlockModalTypes } from '@24i/nxg-sdk-smartott/src/components/BlockedModal/types';
import useBlockedModal from '@24i/nxg-sdk-smartott/src/components/BlockedModal/hooks/useBlockedModal';
import { useAssetPinControl } from '@24i/nxg-sdk-pin-protection/src/hooks/useAssetPinControl';
import View from './View';
import {
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
} from './components/BottomPart';
import { useViewModel } from './viewModel';
import getDetailsScreenStyles from './styles';
// use when SSR is re-implemented
// import * as prerenderFunctions from '../../pages/details/[type]/[id]';
import DetailsScreenContent from './components/DetailsScreenContent';
import EpisodePicker from './components/EpisodePicker';
import { DetailsScreenProps } from './types';

const Main = (props: DetailsScreenProps) => {
    const viewModelProps = useViewModel(props);
    const { openBlockedModal } = useBlockedModal();
    const { hasAgeBlocker, hasAdultBlocker } = useAssetPinControl({
        ...props,
        ...viewModelProps,
    });
    // TODO: We need to implement here whole logic which will be folowing this documentation https://aferian.atlassian.net/wiki/spaces/PRJ001NXG/pages/50336152/Age+restriction
    useEffect(() => {
        if (hasAgeBlocker || hasAdultBlocker) {
            openBlockedModal(BlockModalTypes.MVPD_PROTECTION_LEVEL_FULL);
        }
    }, [hasAgeBlocker, hasAdultBlocker]);

    return <View {...viewModelProps} />;
};

export default {
    Main,
    View,
    useViewModel,
    getDefaultStyles: getDetailsScreenStyles,
    // use when SSR is re-implemented
    // page: {
    //    details: {
    //        '[type]': {
    //            '[id]': prerenderFunctions,
    //        },
    //    },
    // },
    // components
    DetailsScreenContent,
    EpisodePicker,
    // default render functions
    defaultRenderEpisodePicker,
    defaultRenderPackshotGrid,
    defaultRenderDetailsTab,
};
